@import "common/styles/layout.less";

/* You can add global styles to this file, and also import other style files */
@danger: #610300;

@notification-background: #fff;
@notification-color: #000;

body {
  margin: 0;
  font-family: Inter;
  background-color: #FBFBFB;
}

html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  line-height: 1.2;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14px;
}

.bg {
  background-color: #fff;
}

.text {
  font-size: 14px;
  line-height: 17px;
}

.note {
  font-size: 12px;
  color: #4B4B4B;
}

a,
.link {
  font-size: 14px;
  color: #1B1264;
  text-decoration: none;
  cursor: pointer;
}

.line {
  background-color: #43AAC3;
}

.dialog-panel {
  padding: 0 20px;
}

.subscribe-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.no-padding-panel {
  padding: 0;

  .mat-dialog-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .mat-dialog-title {
    margin-bottom: 5px;
  }

  .mat-dialog-content {
    flex: 1;
    max-height: 80vh;
    margin: 0 -9px;
    padding: 0 9px;
  }

  .mat-dialog-actions {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.mat-snack-bar-container.notification,
.mat-snack-bar-container.notification-error {
  background-color: @notification-background;
  color: @notification-color;
  animation: notificationIn 0.3s ease;
  animation-fill-mode: backwards;

  .mat-simple-snackbar {
    justify-content: center;
  }
}

.notification-error {
  border: 1px solid red;
  color: @danger;
}

@keyframes notificationIn {
  from {
    transform: translateY(60px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

button {
  border-radius: 4px !important;
}

// loader
.page-loader {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -20px auto auto -20px;
  border-bottom: 2px #43AAC3 solid;
  z-index: 2;
  animation: 1s rotation ease-in-out infinite;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 1px solid #43AAC3;
    opacity: .5
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

div.mat-mdc-autocomplete-panel {
  background: white !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)  !important;
  padding: 0 !important;;

  mat-option {
    &:hover {
      background: rgba(0, 0, 0, .04) !important;
    }
  }
}
