@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot');
  src: url('/assets/fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?1') format('truetype'),
    url('/assets/fonts/icomoon.woff') format('woff'),
    url('/assest/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-location:before {
  content: "\e900";
}

.icon-time:before {
  content: "\e901";
}

.icon-company:before {
  content: "\e902";
}

.icon-clock:before {
  content: "\e903";
}

.icon-warning:before {
  content: "\e904";
}

.icon-cross:before {
  content: "\e905";
}

.icon-arrow-down:before {
  content: "\e906";
}

.icon-search:before {
  content: "\e907";
}

.icon-dropbox:before {
  content: "\eaae";
}

.icon-googledrive:before {
  content: "\ea8f";
}
