@import "./variables.less";

@line-height: 20px;

.line {
  background-color: @main;
  height: @line-height;
}

.page-content {
  min-height: calc(100% - @line-height);
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.block {
  margin-top: 16px;
  display: block;
}

.footer {
  display: block;
  margin-top: auto;
}
